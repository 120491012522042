<template>
  <v-container class="my-5 main-content" >
    <div class="Title" >
      <h1 class="text-h5"> アカウント</h1>
    </div>
    <div class='justify-left'  style="width:100%; overflow:auto; " >
    <v-card
      elevation="4"
      max-width="700"
      width="98%"
      class="my-10 mx-1 px-4 pb-2"
    >
    <v-card-title class="my-1 text--secondary">ユーザー詳細</v-card-title>

    <v-simple-table
      :loading="loading"
      class="text--secondary ml-3 "
    >
      <template v-slot:default>
  
        <tbody v-if="!$vuetify.breakpoint.xs">
          <tr
            v-for="item in items"
            :key="item.name"
            
          >
            <th class="text--secondary font-weight-bold" >{{ item.caption }}</th>
            <td>{{ item.data }}</td>
          </tr>
        </tbody>

        <v-container v-else>
          <v-row v-for="item in items" :key="item.name">
            <v-col cols=12 class="text--secondary font-weight-bold" >{{ item.caption }}</v-col>
            <v-col cols=12 class="text-caption ml-2">{{ item.data }}</v-col>
            <v-col cols=11 class="mx-auto" style="border-top:1px solid gray; opacity:0.5;   "></v-col>
          </v-row>
        </v-container>
      </template>
    </v-simple-table>
    <v-card-actions class="d-flex flex-wrap justify-left my-3">
      <v-btn
        outlined
        class="ma-1"
        color="teal accent-4"
        to="/change_password"
      >
        パスワードの変更
      </v-btn>
      <v-btn v-if="this.$store.state.user_model.userData.status == 0"
        outlined
        class="ma-1"
        color="teal accent-4"
        to="withdraw"
      >
        退会する
      </v-btn>
      <v-btn
        outlined
        class="ma-1"
        color="blue-grey lighten-1"
        @click="dialog=true"
      >
        利用規約の確認
      </v-btn>

      <v-spacer></v-spacer>

      <v-switch
        v-model="isLoginMail"
        :label="`サインイン通知`"
        @click="onToggleChange"
        color="narekomu"
        :disabled="loading"
    ></v-switch>
    </v-card-actions>
    
    </v-card>
    </div>

    <v-dialog v-model="dialog" max-width="600px" :max-height="$vuetify.breakpoint.xs?'100%':'400'" :fullscreen="$vuetify.breakpoint.xs" style="z-index:2100;" >
      <DialogTerms
        v-on:closeTermsEmit="close"
        :max-height="$vuetify.breakpoint.xs?'100%':'400'"
      />
     </v-dialog>

  </v-container>
</template>

<script>
import axios from "axios"
const DialogTerms = () => import('@/components/DialogTerms.vue');

export default {
  components: { DialogTerms },
    name: 'Account',

    data: () => ({
      dialog:false,
      loading:false,
      isLoginMail:false,
      items:[
        { caption:"ユーザー名",data:""},
        { caption:"契約プラン" , data:""},
        { caption:"コンテンツのデータ容量" , data:""},
        { caption:"3Dモデルのデータ容量" , data:""},
        { caption:"画像のデータ容量" , data:""},
        { caption:"PDFのデータ容量" , data:""},
        { caption:"音声のデータ容量" , data:""},
        { caption:"動画のデータ容量" , data:""},
        { caption:"ナビゲーションの個数" , data:""},
        { caption:"最終サインイン日時", data:""}
      ],
      itemList:["userId","status","total","3Dmodel","image","pdf","sound","movie","navi","lastLogin"],
      itemNameList:["ユーザー名","契約プラン","コンテンツのデータ容量","3Dモデルのデータ容量","画像のデータ容量","PDFのデータ容量","音声のデータ容量","動画のデータ容量","ナビゲーションの個数","最終サインイン日時"],
      statusType:["Free","Standard","Enterprise","Unlimited"]

    }),

  async created (){
    await this.getData();
    this.isLoginMail = this.$store.state.user_model.userData.isLoginNotification;
  },

  computed: {
    // storeのuser_model userIdを監視
    isUser: function(){
      return this.$store.state.user_model.userData.userId;
    },
    isLoginNotification: function(){
      return this.$store.state.user_model.userData.isLoginNotification;
    },

  },

  watch:{
    isUser(){
      this.getData();
    },
    isLoginNotification(){
      this.isLoginMail = this.$store.state.user_model.userData.isLoginNotification
    }
  },


  methods:{

    //storeのアカウント情報の取得
    getData: async function () {
      if(this.$store.state.user_model.userData.userId){

        this.items = [];
        let res = this.$store.state.user_model.userData;
        console.log(res)
        for(let i = 0; i < this.itemList.length; i++){
          let item = this.itemList[i];
          let caption = this.itemNameList[i];
          let data = "";
          if(item == "userId") data = res[item]; 
          else if(item == "status") data = this.statusType[res[item]];
          else if(item == "lastLogin"){
            let dateString = new Date(res[item]);
            let date = new Date(dateString);
            let year = date.getFullYear();
            let month = (date.getMonth() + 1).toString().padStart(2, '0');
            let day = date.getDate().toString().padStart(2, '0');
            let hours = date.getHours().toString().padStart(2, '0');
            let minutes = date.getMinutes().toString().padStart(2, '0');
            let seconds = date.getSeconds().toString().padStart(2, '0');
            data = `${year}年${month}月${day}日 ${hours}時${minutes}分${seconds}秒`;
          }
          else {
            let s = res[item]
            let usage = Math.round(s.usage*100)/100;
            let rate = Math.round(s.usageRate*10)/10;
            let unit = (item == "navi") ?  "個":"GB";
            if(item=="navi" || item=="total"){
              data = ` ${usage} / ${s.limit} ${unit} (${rate} %) `
            }else{
              data = ` ${usage} (${rate} %) `
            }
       
          }
          this.items.push({caption:caption,data:data})
        }

        this.loading = false;
      }else{
        this.loading = true;
      }

    },

    onToggleChange() {  
      this.loading = true;
      axios({
        method: "POST",
        url:
          `${process.env.VUE_APP_URL}${this.$store.state.auth.login.customerId}/account`,
        headers: {
          "X-Api-Key": process.env.VUE_APP_KEY,
          "SessionId": this.$store.state.auth.login.sessionId,
          "Content-Type":"application/json" ,
        },
        data:{"IsLoginNotification":this.isLoginMail},
      }).then((res) => {
        console.log(res)
        this.loading = false;
        this.$store.state.user_model.userData.isLoginNotification = this.isLoginMail;
        

      }).catch(reason => {
        console.log(reason)
        this.loading = false;
      });    
  },

    close (){
      this.dialog = false;
    }
  }

  }
</script>


<style scoped>

  .Title > h1 {
    color: gray;
    font-weight: 200;
    border-bottom: 1px solid gray;
  }

  
.v-application{
  font-family: 'Noto Sans JP', sans-serif;
}

.application {
  font-family: 'Noto Sans JP', sans-serif;
}

</style>